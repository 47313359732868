<template>
    <BackofficeBase :loader_prop="loader_prop">
     
      <div v-for="casuals_couriers in data" :key="casuals_couriers.id" class="item_list">
        <div>
          {{casuals_couriers.company_name}} <br>
          {{casuals_couriers.phone}}
        </div>
        <div class="item_action">
        
            <button v-if ="casuals_couriers.blocked == 0" @click="blokCourier(casuals_couriers.id)">חסום</button> 
            <button v-if ="casuals_couriers.blocked == 1" @click="blokCourier(casuals_couriers.id)">בטל חסימה</button> 
            <br>
            <router-link :to="{path : '/backoffice/casuals_couriers_details', query : {'id' : casuals_couriers.id}}">פרטי משלוחים</router-link> |
        </div>
      </div>
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  
  export default {
    name: 'BackofficeUsers',
    components: {
      BackofficeBase
    },
    data() {
      return {
        loader_prop : true,
        data : []
      }
    },
    methods: {
    async blokCourier(id) {
      let backendModel = new BackendModel();
      let response = await backendModel.backendRequest("/Api/service/backoffice/block_courier", { id: id });
      if (response.data.res == "OK") {
        await this.fetchCouriers();  // טעינה מחדש של הנתונים לאחר חסימה
      }
    },
    async fetchCouriers() {
      let backendModel = new BackendModel();
      let data = await backendModel.backendRequest("/Api/service/backoffice/get_casual_couriers", {});
      this.data = data.data.data;
      this.loader_prop = false;
    }
  },
  async mounted() {
    await this.fetchCouriers();  // קריאה ראשונית לפונקציה שמבצעת את הבקשה ל-API
  }
}
  </script>
  
  <style scoped>
  @import "../../assets/style.css"
  </style>
  